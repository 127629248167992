import axios from "axios";

export default {
  index(params) {
    return axios.get(`crm/suggestion-log?${params}`);
  },

  find(id) {
    return axios.get(`crm/suggestion-log?suggestion_id=${id}`);
  },

  filter(payload) {
    return axios.post("clusters/all/filter", payload);
  },

  add(payload) {
    return axios.post("crm/suggestion-log", payload);
  },

  update(payload) {
    return axios.post("crm/suggestion-log/update", payload);
  },

  delete(id) {
    return axios.delete(`crm/suggestion-log`, {
      data: {
        suggestion_id: id,
      },
    });
  },

  comment(payload) {
    return axios.post("crm/suggestion-log/comment", payload);
  },

  updateComment(payload) {
    return axios.post("crm/suggestion-log/comment/update", payload);
  },

  deleteComment(payload) {
    return axios.delete("crm/suggestion-log/comment", { data: payload });
  },
};
