<template>
  <el-select
    v-model="setStatus"
    placeholder=""
    :class="['suggestion-status', setStatus]"
    @change="changeStatus"
  >
    <el-option
      v-for="(status, index) in statuses"
      :key="index"
      :label="status.label"
      :value="status.value"
    >
      <span
        ><span :class="['suggestion-status--indicator', status.value]"></span
        >{{ status.label }}</span
      >
    </el-option>
  </el-select>
</template>

<script>
import suggestions from "@/requests/feedback/suggestions";

export default {
  name: "SuggestionStatus",
  props: {
    status: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {
      statuses: [
        {
          value: "pending",
          label: "Pending",
        },
        {
          value: "being-considered",
          label: "Being considered",
        },
        {
          value: "implemented",
          label: "Implemented",
        },
        {
          value: "not-accepted",
          label: "Not accepted",
        },
        {
          value: "already-exists",
          label: "Already exists",
        },
      ],
    };
  },
  computed: {
    setStatus: {
      get() {
        return this.status;
      },
      set(value) {
        this.$emit("update:status", value);
      },
    },
    suggestionId() {
      return this.$route.params.id;
    },
  },
  methods: {
    changeStatus(item) {
      if (item === "resolved") {
        this.show = true;
      } else {
        const payload = {
          suggestion_id: this.suggestionId,
          status: item,
        };
        suggestions
          .update(payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.sending = false;
            }
          })
          .catch((error) => {
            this.$message.error(error.response?.data.message);
            this.sending = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.suggestion-status--indicator {
  display: inline-block;
  position: relative;
  top: -2px;
  margin-right: 8px;
  height: 5px;
  width: 5px;
  border-radius: 100px;
  background: var(--eden-grey-quinary);

  &.being-considered {
    background: var(--eden-orange-quaternary);
  }
  &.not-accepted {
    background: var(--eden-red-quaternary);
  }
  &.implemented {
    background: var(--eden-green-quaternary);
  }
}

.suggestion-status {
  width: 160px;

  .el-input__suffix-inner {
    i {
      color: var(--eden-grey-primary) !important;
      font-weight: 600;
    }
  }

  .el-input__inner {
    border: none;
    background-color: var(--eden-grey-septenary);
    transition: background-color 0.25s ease-in;
  }

  &.being-considered {
    .el-input__inner {
      background-color: var(--eden-orange-senary);
      transition: background-color 0.25s ease-in;
    }
  }

  &.not-accepted {
    .el-input__inner {
      background-color: var(--eden-red-senary);
      transition: background-color 0.25s ease-in;
    }
  }

  &.implemented {
    .el-input__inner {
      background-color: var(--eden-green-senary);
      transition: background-color 0.25s ease-in;
    }
  }
}
</style>
