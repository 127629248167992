var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('eden-page-header',{attrs:{"title":'Suggestions',"subtitle":`${_vm.suggestionTitle}`}},[_c('template',{slot:"actions"},[(
          _vm.allowAccessFor([
            'superadmin',
            'admin',
            'operations',
            'kitchen',
            'growth',
            'gardener',
            'gardener_pro',
          ])
        )?_c('suggestion-actions',{attrs:{"suggestion":_vm.suggestion},on:{"edited":_vm.getSuggestion,"deleted":_vm.goToIndex}}):_vm._e()],1)],2),(_vm.loading)?_c('eden-loader'):_vm._e(),_c('div',{staticClass:"suggestion"},[_c('div',{staticClass:"suggestion-body"},[_c('div',{staticClass:"suggestion-body--header"},[_c('div',[_c('h5',[_vm._v(_vm._s(_vm.suggestionTitle))])])]),_c('div',{staticClass:"suggestion-body--description"},[_c('h6',[_vm._v("Description")]),_c('p',[_vm._v(_vm._s(_vm.suggestion.description))])]),_c('div',{staticClass:"suggestion-body--attachments"},[_c('h6',[_vm._v("Attachments")]),_c('eden-attachments',{attrs:{"attachments":_vm.suggestion.attachments}})],1),_c('div',{staticClass:"suggestion-body--comments"},[_c('h6',[_vm._v("Comments")]),_c('suggestion-comments',{attrs:{"comments":_vm.suggestion.comments},on:{"refresh":_vm.getSuggestion}})],1)]),_c('suggestion-summary',{attrs:{"suggestion":_vm.suggestion}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }