<template>
  <div class="comments">
    <div class="comment-new">
      <eden-avatar :size="32" />
      <div class="w-100" tabindex="0" @blur="unSubmit">
        <el-input
          v-model="comment"
          id="textarea"
          type="textarea"
          @focus="submit"
          placeholder="Type your comment here..."
          @keyup.native="typing"
        />
        <el-button
          v-if="hide"
          class="comment-btn"
          type="primary"
          :disabled="disabled"
          :loading="loading"
          @click.prevent="commentOnSuggestion"
          >Post</el-button
        >
      </div>
    </div>
    <template v-if="comments.length">
      <div v-for="(comment, index) in allComments" :key="index" class="comment">
        <eden-avatar :size="32" />
        <div class="comment-author">
          <div class="is-flex justify-between align-center">
            <h6>
              {{ comment.commenter.name }}
              <span v-if="!comment.isEditing"
                >{{ formatTimeFromNow(comment.created_at, true) }}
                {{
                  getDateDifference({
                    fDate: comment.updated_at,
                    sDate: comment.created_at,
                    unit: "seconds",
                  }) > 0
                    ? ` (edited ${formatTimeFromNow(comment.updated_at, true)})`
                    : ""
                }}
              </span>
            </h6>
            <suggestion-comment-actions
              v-if="!comment.isEditing"
              @click:edit="handleEdit(index)"
              @click:delete="deleteComment(index)"
            />
          </div>
          <p v-if="!comment.isEditing">{{ comment.comment }}</p>
          <div
            class="comment-new"
            tabindex="0"
            @focus="submit(`${index}-comment-textarea`)"
            @blur="unSubmit(`${index}-comment-textarea`)"
            v-else
          >
            <el-input
              :rows="5"
              v-model="commentToEdit"
              :id="`${index}-comment-textarea`"
              type="textarea"
            />
            <div class="is-flex comment-btn">
              <el-button
                type="primary"
                :loading="comment.editLoading"
                @click.prevent="editComment(index)"
                >Save</el-button
              >
              <el-button type="white" @click.prevent="cancelEdit(index)"
                >Cancel</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </template>
    <eden-content-empty v-else :text="'No comments'" />
  </div>
</template>

<script>
import suggestions from "@/requests/feedback/suggestions";

export default {
  name: "SuggestionComments",
  components: {
    SuggestionCommentActions: () => import("./SuggestionCommentActions"),
  },
  props: {
    comments: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      comment: "",
      loading: false,
      disabled: true,
      hide: false,
      allComments: this.comments,
      commentToEdit: "",
    };
  },
  computed: {
    suggestionId() {
      return this.$route.params.id;
    },
  },
  methods: {
    submit(_, id = "textarea") {
      document.getElementById(id).style.minHeight = "120px";
      if (id === "textarea") this.hide = true;
    },
    unSubmit(_, id = "textarea") {
      document.getElementById(id).style.minHeight = "50px";
      this.hide = false;
    },
    commentOnSuggestion() {
      this.disabled = false;
      this.loading = true;
      const { logged_in_userId } = this.$store.getters.user;
      const payload = {
        commenter_id: logged_in_userId,
        comment: this.comment,
        suggestion_id: this.suggestionId,
      };
      suggestions
        .comment(payload)
        .then((response) => {
          this.$message.success(response.data.message);
          this.$emit("refresh");
          this.comment = "";
          this.disabled = true;
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        })
        .finally(() => {
          this.unSubmit("textarea");
          this.loading = false;
        });
    },
    typing() {
      this.disabled = false;
    },
    handleEdit(index) {
      const comment = this.allComments[index];
      this.allComments.splice(index, 1, { ...comment, isEditing: true });
      this.commentToEdit = comment.comment;
    },
    cancelEdit(index) {
      const comment = this.allComments[index];
      this.allComments.splice(index, 1, { ...comment, isEditing: false });
      this.commentToEdit = "";
    },
    editComment(index) {
      const comment = this.allComments[index];
      this.allComments.splice(index, 1, {
        ...comment,
        comment: this.commentToEdit,
        editLoading: true,
      });
      suggestions
        .updateComment({
          comment_id: comment.id,
          comment: this.commentToEdit,
        })
        .then((response) => {
          this.allComments.splice(index, 1, {
            ...comment,
            editLoading: false,
          });
          const { status, data } = response.data;

          if (status) {
            this.allComments.splice(index, 1, {
              ...comment,
              isEditing: false,
              comment: this.commentToEdit,
              updated_at: data.updated_at,
            });
            this.commentToEdit = "";
          }
        })
        .catch((error) => {
          this.allComments.splice(index, 1, {
            ...comment,
            isEditing: false,
            editLoading: false,
          });
          this.$message.error(error.response.data.message);
        });
    },
    deleteComment(index) {
      const comment = this.allComments[index];
      this.allComments.splice(index, 1);

      suggestions
        .deleteComment({
          comment_id: comment.id,
        })
        .then((response) => {
          const { status, message } = response.data;
          if (status) this.$message.success(message);
        })
        .catch((error) => {
          this.allComments.splice(index, 0, comment);
          this.$message.error(error.response.data.message);
        });
    },
  },
  watch: {
    comments(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.allComments = [...newValue];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.comments {
  margin-top: 30px;
}

.comment-new {
  display: flex;
  margin-bottom: 40px;
  position: relative;

  > div {
    margin-left: 10px;
  }
  .comment-btn {
    position: absolute;
    right: 1%;
    bottom: 5%;
  }
}

.comment {
  display: flex;
  margin-bottom: 40px;

  &-author {
    margin-left: 15px;
    width: 100%;

    h6 {
      font-size: 0.95rem;
      font-family: "CerebriSans", sans-serif;
      font-weight: 500;
      margin-bottom: 12px;
    }

    span {
      position: relative;
      display: inline-block;
      margin-left: 10px;
      padding-left: 15px;
      font-size: 1rem !important;
      font-weight: 400;
      color: var(--eden-grey-tertiary);

      &::before {
        content: "";
        position: absolute;
        height: 4px;
        width: 4px;
        border-radius: 10px;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background: var(--eden-grey-tertiary);
      }
    }

    p {
      line-height: 1.8;
      font-size: 0.9rem;
    }
  }
}
</style>
