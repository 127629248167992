<template>
  <div class="suggestion-summary">
    <div class="suggestion-summary--section">
      <p>Status</p>
      <suggestion-status :status.sync="suggestion.status" />
    </div>
    <div class="suggestion-summary--section">
      <p>Suggestion Originator</p>
      <div class="suggestion-personnel">
        <eden-avatar :size="24" />
        <router-link
          v-if="suggestion.originatorType === 'customer'"
          class="text-primary"
          :to="{
            name: 'customers.individual',
            params: { id: suggestion.suggester.id },
          }"
        >
          {{ suggestion.suggester && suggestion.suggester.name }}</router-link
        >
        <span v-else
          >{{
            suggestion.suggester && suggestion.suggester.name
          }}
          (Humaan)</span
        >
      </div>
    </div>
    <div class="suggestion-summary--section">
      <p>Concerned Department</p>
      <span>{{ suggestion.department }}</span>
    </div>
    <div class="suggestion-summary--section">
      <p>Assigned Team Members</p>
      <div
        v-for="(resolver, index) in suggestion.team_members"
        :key="index"
        class="suggestion-personnel"
      >
        <eden-avatar :size="24" />
        <span>{{ resolver.name }}</span>
      </div>
    </div>

    <div class="suggestion-summary--section">
      <p>
        Suggestion created by {{ suggestion.actor && suggestion.actor.name }}
      </p>
      <span class="date"
        >On {{ formatDate(suggestion.created_at, "m do, y; H:mm A") }}</span
      >
    </div>
  </div>
</template>

<script>
import SuggestionStatus from "@/components/Feedback/Suggestions/Suggestion/Structure/SuggestionStatus";

export default {
  name: "SuggestionSummary",
  components: { SuggestionStatus },
  props: {
    suggestion: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.suggestion-summary--section {
  margin-bottom: 30px;

  > p:first-child {
    color: var(--eden-grey-tertiary);
    font-size: 0.875rem;
  }

  span {
    font-size: 0.875rem;
    &.date {
      color: var(--eden-grey-tertiary);
    }
  }

  .suggestion-personnel {
    display: flex;
    align-items: center;

    a,
    span {
      font-size: 0.875rem;
      margin-left: 10px;
      display: inline-block;
    }

    + .suggestion-personnel {
      margin-top: 15px;
    }

    &:only-of-type {
      margin-bottom: 8px;
    }
  }
}
</style>
