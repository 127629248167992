<template>
  <div>
    <el-dropdown @command="command">
      <el-button type="plain">
        Actions <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <template v-for="(action, i) in actions">
          <el-dropdown-item
            v-if="allowAccessFor(action.access)"
            :icon="action.icon"
            :key="i"
            :command="{
              command: action.command,
              component: action.component,
              successEvent: action.successEvent,
            }"
          >
            {{ action.label }}</el-dropdown-item
          >
        </template>
      </el-dropdown-menu>
    </el-dropdown>
    <component
      :is="action.component"
      :show.sync="action.visibility"
      :suggestion="suggestion"
      :action="action.command"
      @success="$emit(action.successEvent)"
    />
  </div>
</template>

<script>
export default {
  name: "SuggestionActions",
  props: {
    suggestion: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    SuggestionForm: () => import("../../SuggestionForm"),
    SuggestionDelete: () => import("./SuggestionDelete"),
  },
  data() {
    return {
      action: {
        command: "",
        component: "",
        visibility: false,
      },
      actions: [
        {
          access: "all",
          icon: "eden-icon-edit",
          label: "Edit suggestion",
          command: "edit",
          component: "suggestion-form",
          successEvent: "edited",
        },
        {
          access: ["admin", "operations"],
          icon: "eden-icon-delete",
          label: "Delete suggestion",
          command: "delete",
          component: "suggestion-delete",
          successEvent: "deleted",
        },
      ],
    };
  },
  methods: {
    command(command) {
      this.action.command = command.command;
      this.action.component = command.component;
      this.action.successEvent = command.successEvent;
      setTimeout(() => {
        this.action.visibility = true;
      }, 100);
    },
  },
};
</script>
