export const mapCreateSuggestionLogDataForBackend = (data) => ({
  actor_id: data.actor_id,
  suggester_id: data.originator.id,
  title: data.title,
  description: data.description,
  department: data.department.name,
  department_tag: data.department.tag,
  team_members_id: data.teamMembers?.map((member) => member.id),
  customer_type: data.originatorType,
  status: data.status,
  attachments: data.attachments?.map((attachment) => ({
    name: attachment.name,
    url: attachment.url,
  })),
});

export const mapSuggestionLogDataFromBackend = (data) => ({
  id: data.id,
  title: data.title,
  description: data.description,
  originatorType: data.customer_type,
  actor_id: data.actor.id,
  actor: data.actor,
  suggester_id: data.suggester.id,
  suggester: data.suggester,
  department: data.department,
  department_tag: data.department_tag,
  team_members_id: data.team_members?.map((member) => member.id),
  team_members: data.team_members,
  status: data.status,
  attachments: data.attachments?.map((attachment) => ({
    name: attachment.name,
    url: attachment.url,
  })),
  comments: data.comments.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at),
  ),
  created_at: data.created_at,
});
