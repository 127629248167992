<template>
  <div>
    <eden-page-header :title="'Suggestions'" :subtitle="`${suggestionTitle}`">
      <template slot="actions">
        <suggestion-actions
          v-if="
            allowAccessFor([
              'superadmin',
              'admin',
              'operations',
              'kitchen',
              'growth',
              'gardener',
              'gardener_pro',
            ])
          "
          :suggestion="suggestion"
          @edited="getSuggestion"
          @deleted="goToIndex"
        />
      </template>
    </eden-page-header>
    <eden-loader v-if="loading" />
    <div class="suggestion">
      <div class="suggestion-body">
        <div class="suggestion-body--header">
          <div>
            <h5>{{ suggestionTitle }}</h5>
          </div>
        </div>
        <div class="suggestion-body--description">
          <h6>Description</h6>
          <p>{{ suggestion.description }}</p>
        </div>
        <div class="suggestion-body--attachments">
          <h6>Attachments</h6>
          <eden-attachments :attachments="suggestion.attachments" />
        </div>
        <div class="suggestion-body--comments">
          <h6>Comments</h6>
          <suggestion-comments
            :comments="suggestion.comments"
            @refresh="getSuggestion"
          />
        </div>
      </div>
      <suggestion-summary :suggestion="suggestion" />
    </div>
  </div>
</template>

<script>
import SuggestionActions from "@/components/Feedback/Suggestions/Suggestion/Actions/SuggestionActions";
import SuggestionComments from "@/components/Feedback/Suggestions/Suggestion/Structure/SuggestionComments";
import SuggestionSummary from "@/components/Feedback/Suggestions/Suggestion/Structure/SuggestionSummary";

import suggestions from "../../../requests/feedback/suggestions";
import { mapSuggestionLogDataFromBackend } from "@/helpers/api-mappers/suggestion";

export default {
  name: "Suggestion",
  components: { SuggestionSummary, SuggestionComments, SuggestionActions },
  data() {
    return {
      loading: false,
      suggestion: {},
    };
  },
  computed: {
    suggestionId() {
      return this.$route.params.id;
    },
    suggestionTitle() {
      return this.suggestion && this.formatToTitleCase(this.suggestion.title);
    },
  },
  created() {
    this.getSuggestion();
  },
  methods: {
    goToIndex() {
      this.$router.replace({ name: "feedback.suggestions.index" });
    },
    getSuggestion() {
      this.loading = true;
      suggestions
        .find(this.suggestionId)
        .then((response) => {
          this.loading = false;
          const { status, data } = response.data;

          if (status && data.length) {
            this.$emit("logged");
            this.suggestion = mapSuggestionLogDataFromBackend(data[0]);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
$-summary-width: 250px;

.suggestion {
  position: relative;
  width: 100%;
  display: flex;

  &-body {
    width: calc(100% - #{$-summary-width});

    > div {
      &:not(:last-child) {
        margin-bottom: 40px;
      }

      h6 {
        font-size: 1rem;
        font-family: "CerebriSans", sans-serif;
        margin-bottom: 15px;
      }
    }

    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h5 {
        font-size: 1.5rem;
        font-family: "CerebriSans", sans-serif;
        margin-bottom: 10px;
      }
    }

    &--description {
      p {
        font-size: 0.875rem;
        line-height: 1.8;
      }
    }

    &--comments {
      border-top: 1px solid var(--eden-grey-septenary);
      padding-top: 40px;
    }
  }

  &-summary {
    width: $-summary-width;
    border-left: 1px solid var(--eden-grey-septenary);
    padding-left: 40px;
    margin-left: 40px;
  }
}
</style>
